import React from "react";
import { Link } from "gatsby";
import { Layout, Row, Col, BackTop, Button } from "antd";
import Header from "../../components/common/header";
import { Helmet } from "react-helmet";
import CommonFooter from "../../components/common/footer";
import TutorialSider from "../../components/guide/tutorial-sider";

import css from "../../styles/guide.css";

const TutorialPart3 = ({}) => (
  <div>
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="How DayViewer can help you track payments and expenses and improve budgeting. DayViewer Tutorial - Payment, Expenses tracking &amp; Budgeting "
        />
        <title>DayViewer Tutorial - Payment Tracking &amp; Budgeting</title>
      </Helmet>
      <Header />
      <Layout style={{ minHeight: "100vh" }}>
        <BackTop />
        <TutorialSider />
        <Layout className="guide-body">
          <h1>Payment Tracking</h1>
          <Row>
            <Col>
              <h3>Intro</h3>
              <p>
                All this tea purchasing is costing us, so we better{" "}
                <strong>budget for the tea </strong>. Payments are like other
                calendar entries (Tasks &amp; Events) in which they have a date
                attached.
              </p>

              <p>
                As with the previous tutorials, we recommend opening a new
                browser tab and follow the steps along. It should take less than
                5 minutes to complete this.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="tutorial-steps"></div>
              <h3> Create Payment Entry</h3>
              <p>
                After logging into DayViewer, select <strong>Payments</strong>{" "}
                in the Side Panel Menu
              </p>
              <p>
                We will again use the <strong>Quick Entry</strong> form. (on
                mobile, the quick entry will drop down when you click the{" "}
                <Button icon="plus-circle" type="primary" /> button ){" "}
              </p>
              <p>
                Enter <strong>"Salary"</strong> as the Title. Add the rest of
                the information making sure you <strong>Select Income</strong>.
                Click <strong>ADD</strong> button to create the Payment - Income
                entry.
              </p>
              <div className="tutorial-images">
                <img
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/tutorial-payment-add.png"
                  alt="add payment"
                  title="Add Payment"
                />
              </div>
              <div className="tutorial-steps">
                <p>
                  Now we will add an expense, of the value of the approximate
                  the cost of the <strong>Tea Shopping</strong> (ingredients)
                  that we created in the previous tutorial. let's say $10 - go
                  ahead and create an <strong>Tea Expense</strong> entry in the
                  payment system. Your payment table should now have 2 entries,
                  an income &amp; an expense.
                </p>
              </div>
              <div className="tutorial-steps">
                <p>
                  Next we will attach the Beverage label we made in the earlier
                  tutorial to the expense. Do this by clicking on the{" "}
                  <Button icon="edit" type="primary" /> button in the table row
                  of the Tea S
                </p>
                <div className="tutorial-images">
                  <img
                    src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/tutorial-payment-label.png"
                    alt="label payment"
                    title="Attach Label to Payment"
                  />
                </div>
              </div>
              <div className="tutorial-steps">
                <p>
                  If we now go over to the Dashboard, you can see a summary of
                  payments going in and out over the next 7 days
                </p>
                <div className="tutorial-images">
                  <img
                    src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/tutorial-payment-summary.png"
                    alt="payment summary"
                    title="7 Day Payments Summary"
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <h3>Summary</h3>
              <p>
                By tracking payments going in and out, you can budget on the
                fly. You will be more aware of your spending habits. This can be
                especially eye-opening for businesses where payments are coming
                in and costs are going out of the business regularly.
              </p>
              <p>NB: Payments and Labels are DayViewer Pro features</p>
              <Link to={`/tutorial-content/tutorial-4`}>
                Go to Next Tutorial
              </Link>
            </Col>
          </Row>
        </Layout>
      </Layout>
      <CommonFooter />
    </Layout>
  </div>
);

export default TutorialPart3;
